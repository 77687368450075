import { IExportSchema } from './AthenaClient'
//import deliveryMethods from "static/deliveryMethods.yml";

export default class MetaClient {
  // begin yaml + macro + backing data store?
  public getContractTypes(): string[] {
    return ['Fixed Price', 'GMP', 'Cost Plus Fee']
  }

  public getProcurementMethods(): string[] {
    return [
      'Best Value (Technical & Price) with Discussion',
      'Best Value (Technical & Price) without Discussion',
      'Comp. Sealed Prop. (CSP)/Lump Sum + Best Value',
      'Construction Management',
      'Fee + GC',
      'Hard (Lowest) Bid without Technical Proposal',
      'Hard Bid',
      'Lowest Price Technically Acceptable (LPTA)',
      'Pre-Construction Services',
      'Qualifications-Based Selection',
      'Sole Source',
    ]
  }

  public getDeliveryMethods(): string[] {
    //console.log('!!!oh wow', deliveryMethods['Delivery Methods']);
    return [
      'Agency CM',
      'CM at Risk',
      'CM at Risk (CM/GC)',
      'Cost Plus',
      'Design-Bid-Build (Hard Bid)',
      'Design-Build',
      'Development',
      'Progressive Design Build (PDB)',
      'Integrated Project Delivery (IPD)',
      'Public Private Partnership (PPP)',
      'Time and Material (T&M)',
      'Facility Management',
    ]
  }

  public getSectors(): string[] {
    return ['Private', 'Public - Federal', 'Public - State', 'Public - Local']
  }

  public getReferenceTypes(): string[] {
    return [
      'Owner',
      'Owner Representative',
      'End-User',
      'Designer',
      'Subcontractor',
    ]
  }

  public getDataCenterTiers(): string[] {
    return ['I', 'II', 'III', 'IV', 'N/A']
  }

  public getServiceBusinessUnits() {
    return [
      'Facility Solutions',
      'Building Systems Integration',
      'Specialized Construction',
      'Facility Management',
    ]
  }

  public getInsuranceTypes() {
    return ['CCIP', 'OCIP', 'Standard']
  }

  public getVerticalMarketNames(): string[] {
    const verticalMarkets = [
      'Aviation',
      'Civic',
      'Correctional',
      'Data Center',
      'Energy/Power',
      'Ground Transportation',
      'Healthcare',
      'Higher Education',
      'Hospitality',
      'Housing',
      'Industrial',
      'Infrastructure',
      'K-12',
      'Manufacturing',
      'Mixed-Use',
      'Office',
      'Parking Structure',
      'Research/Laboratories',
      'Retail',
      'Sports & Entertainment',
      'Water/Wastewater',
    ]
    return verticalMarkets
  }
  // end

  // may not be needed anymore - keep til 3/1/2024
  public getVerticalMarkets(): {
    name: string
    image: string
    members: string[]
  }[] {
    return [
      {
        name: 'Advanced Technology',
        image: '/images/vertical_market/advanced_technology.svg',
        members: ['Advanced Technology'],
      },
      {
        name: 'Aerospace',
        image: '/images/vertical_market/aerospace.svg',
        members: ['Aerospace'],
      },
      {
        name: 'Commercial',
        image: '/images/vertical_market/commercial.svg',
        members: ['Commercial'],
      },
      {
        name: 'Corrections',
        image: '/images/vertical_market/corrections.svg',
        members: ['Corrections'],
      },
      {
        name: 'Education',
        image: '/images/vertical_market/education.svg',
        members: ['Education'],
      },
      {
        name: 'Healthcare',
        image: '/images/vertical_market/healthcare.svg',
        members: ['Healthcare'],
      },
      {
        name: 'Hospitality',
        image: '/images/vertical_market/hospitality.svg',
        members: ['Hospitality'],
      },
      {
        name: 'Industrial',
        image: '/images/vertical_market/industrial.svg',
        members: ['Industrial'],
      },
      {
        name: 'Leisure',
        image: '/images/vertical_market/leisure.svg',
        members: ['Leisure'],
      },
      {
        name: 'Multi-Residence',
        image: '/images/vertical_market/multi_residence.svg',
        members: ['Multi-Residence'],
      },
      {
        name: 'Public',
        image: '/images/vertical_market/public.svg',
        members: ['Public'],
      },
      {
        name: 'Transportation',
        image: '/images/vertical_market/transportation.svg',
        members: ['Transportation'],
      },
    ]
  }

  public getNonExportableProperties(entityName: string): IExportSchema[] {
    switch (entityName) {
      case 'people':
        return [
          {
            label: 'Location',
            type: 'string',
            propertyName: 'location',
          },
          {
            label: 'With Hensel Phelps',
            type: 'string',
            propertyName: 'hpExperience',
          },
        ]
      case 'projects':
        return [
          {
            label: 'Location',
            type: 'string',
            propertyName: 'location',
          },
        ]
      default:
        return []
    }
  }

  public getUSStateDictionary(): { [key: string]: string } {
    const states: { [key: string]: string } = {
      al: 'alabama',
      ak: 'alaska',
      az: 'arizona',
      ar: 'arkansas',
      ca: 'california',
      co: 'colorado',
      ct: 'connecticut',
      de: 'delaware',
      dc: 'district of columbia',
      fl: 'florida',
      ga: 'georgia',
      gu: 'guam',
      hi: 'hawaii',
      id: 'idaho',
      il: 'illinois',
      in: 'indiana',
      ia: 'iowa',
      ks: 'kansas',
      ky: 'kentucky',
      la: 'louisiana',
      me: 'maine',
      md: 'maryland',
      ma: 'massachusetts',
      mi: 'michigan',
      mn: 'minnesota',
      mo: 'missouri',
      ms: 'mississippi',
      mt: 'montana',
      ne: 'nebraska',
      nc: 'north carolina',
      nd: 'north dakota',
      nh: 'new hampshire',
      nj: 'new jersey',
      nm: 'new mexico',
      nv: 'nevada',
      ny: 'new york',
      oh: 'ohio',
      ok: 'oklahoma',
      or: 'oregon',
      pa: 'pennsylvania',
      ri: 'rhode island',
      sc: 'south carolina',
      sd: 'south dakota',
      tn: 'tennessee',
      tx: 'texas',
      ut: 'utah',
      vt: 'vermont',
      va: 'virginia',
      wa: 'washington',
      wv: 'west virginia',
      wi: 'wisconsin',
      wy: 'wyoming',
    }
    return states
  }
}
