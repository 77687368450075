import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  ForwardedRef,
} from 'react'
import AthenaClient, {
  ApiPerson,
  IApiPerson,
  IApiProject,
  IApiProjectStakeholder,
} from 'services/apiClients/AthenaClient'
import noop from 'utils/noop'
import { useSharedLargeCardStyles } from '../../shared/sharedStyles'
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Switch,
  Dialog,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import DashDetailsField from 'components/DashDetailsField'
import numberFormatter from 'services/shared/numberFormatter'
import EntityDialog from '../../shared/EntityDialog'
import { getNamedData } from 'utils/forms'
import PersonDetailService from 'services/PersonService'
import { DashItemProgress } from '../../../dashCore/DashItemProgress'
import useAthenaClient from 'hooks/useAthenaClient'
import useSaveCount from 'hooks/useSaveCount'
import DashDivider from 'components/DashDivider'
import {
  IWithDashSectionMethods,
  IWithDashSectionProps,
  withDashSection,
} from '../../shared/withDashSection'
import { projectInfotips, formatInfotip } from 'constants/metadata/infotips'
import { v4 as uuidv4 } from 'uuid'
import { commasFmt, currencyFmt } from 'utils/numberToStringUtil'
import { useGlobalState } from '../../../../app/state/useGlobalState'
// end imports

const SECTION_ID = 'projectDetailsSizeMetricsSection'

const ProjectDetailsMetricsSection = forwardRef<
  IWithDashSectionMethods,
  IWithDashSectionProps & { onSave?: () => void }
>((props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {
  const classes = useSharedLargeCardStyles()

  const [model, setModel] = useState<IApiProject>(props.model)
  const [saveCount, saved] = useSaveCount()
  const { athenaClient } = useAthenaClient()
  const [showInfotipModal, setShowInfotipModal] = useState(false)
  const [content, setContent] = useState('')
  const [sectionKey, setSectionKey] = useState(uuidv4())
  const gs = useGlobalState()

  if (model === null) {
    setModel(props.model)
  }

  const toggleEditMode = () => {
    props.setEditMode(!props.editMode)
  }

  function transform(data: Record<string, unknown>): Record<string, unknown> {
    const newData: Record<string, unknown> = {}

    for (const [key, value] of Object.entries(data)) {
      if (key === 'id') {
        newData[key] = value
        continue
      }

      const transformedValue = parseInt(value + '', 10)
      newData[key] = isNaN(transformedValue) ? value : transformedValue
    }

    return newData
  }
  // v2
  useImperativeHandle(forwardRef, () => ({
    save: async () => {
      // return await compSave();
      console.log('begin ' + SECTION_ID + ' save')
      const elem = document.querySelector('#' + SECTION_ID)
      //const data = getNamedData(elem);
      const data = transform(getNamedData(elem))
      console.log('namedData was:', data)
      try {
        const m = await athenaClient?.patchProject(data)
        setModel(m as IApiProject)
        gs.setCurrentProject(m as IApiProject)
        props.setEditMode(false)
        setSectionKey(uuidv4())
        return true
      } catch (err) {
        console.log(`Error during save (patch entity - ${SECTION_ID}) : `, err)
        return false
      }
    },
  }))
  // end useImperativeHandle

  const handleInfotipClick = (key: string) => {
    const infotip = projectInfotips[key]
    setContent(formatInfotip(infotip))
    setShowInfotipModal(true)
  }

  if (!props.model) {
    return <DashItemProgress />
  }

  return (
    <React.Fragment key={sectionKey}>
      {/* id */}
      <DashDetailsField
        mode="hidden"
        label="Id"
        showLabel={false}
        editMode={props.editMode}
        name="id"
        defaultValue={model.id}
      />

      {/* overallSiteAcreage */}
      <DashDetailsField
        mode="number"
        label="Overall Site Acreage"
        showLabel={true}
        infotipKey="overallSiteAcreage"
        infotipCallback={() => handleInfotipClick('overallSiteAcreage')}
        width="47%"
        editMode={props.editMode}
        name="overallSiteAcreage"
        defaultValue={model.overallSiteAcreage}
        formatFn={commasFmt.format}
      />

      {/* centralUtilityPlantSquareFootage */}
      <DashDetailsField
        mode="number"
        label="Central Utility Plant Square Footage"
        showLabel={true}
        infotipKey="centralUtilityPlantSquareFootage"
        infotipCallback={() =>
          handleInfotipClick('centralUtilityPlantSquareFootage')
        }
        width="47%"
        editMode={props.editMode}
        name="centralUtilityPlantSquareFootage"
        defaultValue={model.centralUtilityPlantSquareFootage}
        formatFn={commasFmt.format}
      />

      {/* numBeds */}
      <DashDetailsField
        mode="number"
        label="Number of Beds"
        showLabel={true}
        infotipKey="numBeds"
        infotipCallback={() => handleInfotipClick('numBeds')}
        width="47%"
        editMode={props.editMode}
        name="numBeds"
        defaultValue={model.numBeds}
        formatFn={commasFmt.format}
      />

      {/* numHousingUnits */}
      <DashDetailsField
        mode="number"
        label="Number of Housing Units"
        showLabel={true}
        infotipKey="numHousingUnits"
        infotipCallback={() => handleInfotipClick('numHousingUnits')}
        width="47%"
        editMode={props.editMode}
        name="numHousingUnits"
        defaultValue={model.numHousingUnits}
        formatFn={commasFmt.format}
      />

      {/* numFloors */}
      <DashDetailsField
        mode="number"
        label="Number of Floors Above Grade"
        showLabel={true}
        infotipKey="numFloors"
        infotipCallback={() => handleInfotipClick('numFloors')}
        width="47%"
        editMode={props.editMode}
        name="numFloors"
        defaultValue={model.numFloors}
        formatFn={commasFmt.format}
      />

      {/* numFloorsBelowGrade */}
      <DashDetailsField
        mode="number"
        label="Number of Floors Below Grade"
        showLabel={true}
        infotipKey="numFloorsBelowGrade"
        infotipCallback={() => handleInfotipClick('numFloorsBelowGrade')}
        width="47%"
        editMode={props.editMode}
        name="numFloorsBelowGrade"
        defaultValue={model.numFloorsBelowGrade}
        formatFn={commasFmt.format}
      />

      {/* parkingGarageSquareFootage */}
      <DashDetailsField
        mode="number"
        label="Parking Garage Sq Ft"
        showLabel={true}
        infotipKey="parkingGarageSquareFootage"
        infotipCallback={() => handleInfotipClick('parkingGarageSquareFootage')}
        width="47%"
        editMode={props.editMode}
        name="parkingGarageSquareFootage"
        defaultValue={model.parkingGarageSquareFootage}
        formatFn={commasFmt.format}
      />

      {/* numParkingSpaces */}
      <DashDetailsField
        mode="number"
        label="Number of Structured Parking Spaces"
        showLabel={true}
        infotipKey="numParkingSpaces"
        infotipCallback={() => handleInfotipClick('numParkingSpaces')}
        width="47%"
        editMode={props.editMode}
        name="numParkingSpaces"
        defaultValue={model.numParkingSpaces}
        formatFn={commasFmt.format}
      />

      {/* numOnGradeParkingSpaces */}
      <DashDetailsField
        mode="number"
        label="Number of On Grade Parking Spaces"
        showLabel={true}
        infotipKey="numOnGradeParkingSpaces"
        infotipCallback={() => handleInfotipClick('numOnGradeParkingSpaces')}
        width="47%"
        editMode={props.editMode}
        name="numOnGradeParkingSpaces"
        defaultValue={model.numOnGradeParkingSpaces}
        formatFn={commasFmt.format}
      />

      {/* numKeysHotel */}
      <DashDetailsField
        mode="number"
        label="Number of Hotel Keys"
        showLabel={true}
        infotipKey="numKeysHotel"
        infotipCallback={() => handleInfotipClick('numKeysHotel')}
        width="47%"
        editMode={props.editMode}
        name="numKeysHotel"
        defaultValue={model.numKeysHotel}
        formatFn={commasFmt.format}
      />

      {/* dataCenterTierLevel */}
      {model.verticalMarket === 'Data Center' && (
        <DashDetailsField
          mode="select"
          label="Tier Level of Data Center"
          showLabel={true}
          infotipKey="dataCenterTierLevel"
          infotipCallback={() => handleInfotipClick('dataCenterTierLevel')}
          width="47%"
          editMode={props.editMode}
          name="dataCenterTierLevel"
          defaultValue={model.dataCenterTierLevel}
          choices={props.referenceData.options['dataCenterTiers']}
        />
      )}

      {/* totalBuildMegawatts */}
      <DashDetailsField
        mode="number"
        label="Total Build Megawatts"
        showLabel={true}
        infotipKey="totalBuildMegawatts"
        infotipCallback={() => handleInfotipClick('totalBuildMegawatts')}
        width="47%"
        editMode={props.editMode}
        name="totalBuildMegawatts"
        defaultValue={model.totalBuildMegawatts}
        formatFn={commasFmt.format}
      />

      {/* scifSquareFootage */}
      <DashDetailsField
        mode="number"
        label="SCIF Square Footage"
        showLabel={true}
        infotipKey="scifSquareFootage"
        infotipCallback={() => handleInfotipClick('scifSquareFootage')}
        width="47%"
        editMode={props.editMode}
        name="scifSquareFootage"
        defaultValue={model.scifSquareFootage}
        formatFn={commasFmt.format}
      />

      {/* rfiTotalCount */}
      <DashDetailsField
        mode="number"
        label="Total RFIs"
        showLabel={true}
        width="47%"
        editMode={props.editMode}
        name="rfiTotalCount"
        defaultValue={model.rfiTotalCount}
        formatFn={commasFmt.format}
      />

      {/* rfiClosedCount */}
      <DashDetailsField
        mode="number"
        label="Closed RFIs"
        showLabel={true}
        width="47%"
        editMode={props.editMode}
        name="rfiClosedCount"
        defaultValue={model.rfiClosedCount}
        formatFn={commasFmt.format}
      />

      {/* rfiPendingCount */}
      <DashDetailsField
        mode="number"
        label="Pending RFIs"
        showLabel={true}
        width="47%"
        editMode={props.editMode}
        name="rfiPendingCount"
        defaultValue={model.rfiPendingCount}
        formatFn={commasFmt.format}
      />

      {/* linearFeet */}
      <DashDetailsField
        mode="number"
        label="Linear Feet"
        showLabel={true}
        infotipKey="linearFeet"
        infotipCallback={() => handleInfotipClick('linearFeet')}
        width="47%"
        editMode={props.editMode}
        name="linearFeet"
        defaultValue={model.linearFeet}
        formatFn={commasFmt.format}
      />

      {/* millionGallonsPerDay */}
      <DashDetailsField
        mode="number"
        label="Million Gallons per Day (MGD)"
        showLabel={true}
        infotipKey="millionGallonsPerDay"
        infotipCallback={() => handleInfotipClick('millionGallonsPerDay')}
        width="47%"
        editMode={props.editMode}
        name="millionGallonsPerDay"
        defaultValue={model.millionGallonsPerDay}
        formatFn={commasFmt.format}
      />

      <DashDivider width="80vw" height="5px" />

      {/*<Dialog*/}
      {/*  open={showInfotipModal}*/}
      {/*  onClose={() => setShowInfotipModal(false)}>*/}
      {/*  <div className={classes.innerDialogPadding}>*/}
      {/*    <div dangerouslySetInnerHTML={{ __html: content }} />*/}
      {/*  </div>*/}
      {/*</Dialog>*/}
    </React.Fragment>
  )
})

export default withDashSection(
  'Metrics',
  SECTION_ID,
  ProjectDetailsMetricsSection,
  false,
  'metrics',
)
